/* .swiper-container { */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* SRQ Customization */

.hero-slider {
  margin-bottom: 60px;
}

.hero-slider-img {
  /* height: 53vh; */
  height: 75vh;
  width: 100%;
  object-fit: cover;
  /* custom added */
  position: "relative";
}

.slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20%;
  background-color: rgba(0, 0, 0, 0.3);
}

.slider-content h1 {
  font-size: 36px;
  font-weight: 500;
  margin: 0 5%;
}

@media (max-width: 900px) {
  .hero-slider-img {
    width: 100%;
  }
  .slider-content {
    padding: 0 12%;
  }
  .slider-content h1 {
    font-size: 32px;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .slider-content h1 {
    font-size: 30px;
  }
}
